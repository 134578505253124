.form-box-bg {
	position: relative;
	overflow: hidden;
	height: auto;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 70px;
	padding-top: 70px;
	img {
		position: absolute;
		width: 1760px;
		height: 484px;
		top: 0;
		right: -160px;
		z-index: 1;
	}
	.main-box {
		// padding: 0 50px 0 173px;
		position: relative;
		z-index: 3;
		h2 {
			color: #0014a0;
			font-size: 22px;
			font-weight: bold;
			line-height: 30px;
		}
		p {
			color: #707070;
			font-size: 14px;
			padding-top: 15px;
			line-height: 23px;
		}
		.main-box-h2-secend {
			padding-top: 51px;
		}
		.main-box-h2-tree {
			padding-top: 37px;
		}
	}
	.form-box {
		padding-right: 71px;
		margin: 0 auto;
		position: relative;
		.form-box-inside {
			padding-top: 38px;
			padding-left: 67px;
			padding-right: 61px;
			background: #0014a0;
			position: relative;
		}
		p {
			font-size: 8px;
			color: #ffffff;
			opacity: 0.5;
			line-height: 11px;
		}
	}
	h3 {
		color: #ffffff;
		font-size: 30px;
	}
	input[type="text"],
	[type="tel"],
	[type="email"],
	[type="number"] input {
		background-color: #0014a0;
		color: #ffffff;
		border: none;
		opacity: 0.5;
		line-height: 22px;
		width: 100%;
		transition: none;
		padding: 0;
	}
	.from-box-inside-index {
		position: relative;
		z-index: 2;
	}
	.button-form-box {
		padding: 21px 0 39px;
		display: flex;
		justify-content: center;
		align-items: center;
		button {
			font-size: 22px;
			color: #0014a0;
			background-color: #ffffff;
			padding: 10px 29px 10px 30px;
			border: 2px solid white;
			border-radius: 24px;
			margin: 0 auto;
			text-align: center;
			text-transform: uppercase;
			a {
				font-size: 22px;
				color: #0014a0;
				transition: 0.4s;
			}
		}
		button:hover {
			background-color: #0014a0;
			color: #fff;
			border: 2px solid #fff;
			padding: 10px 29px 10px 30px;
		}
		a:hover {
			color: #fff;
		}
	}
	input [type="checkbox"] {
		padding: 5px;
	}
	hr {
		opacity: 0.5;
		padding-bottom: 12px;
		margin: 0;
	}
}
input:link[type="text"],
[type="tel"],
[type="email"],
[type="number"] {
	background-color: #0014a0;
	border: none;
	box-shadow: none;
}
input:visited[type="text"],
[type="tel"],
[type="email"],
[type="number"] {
	background-color: #fff;
	border: none;
	box-shadow: none;
	opacity: 1;
}
input:focus[type="text"],
[type="tel"],
[type="email"],
[type="number"] {
	background-color: #0014a0;
	border: none;
	box-shadow: none;
	transition: none;
}
input:hover[type="text"],
[type="tel"],
[type="email"],
[type="number"] {
	background-color: #0014a0;
	border: none;
	box-shadow: none;
	transition: none;
}
input:active[type="text"],
[type="tel"],
[type="email"],
[type="number"] {
	background-color: #0014a0;
	border: none;
	box-shadow: none;
	transition: none;
}
.checbox-text {
	width: 100%;
	height: auto;
	position: relative;
	padding-left: 15px;
	padding-top: 18px;
}
.checbox-text input[type="checkbox"] {
	position: absolute;
	left: 0;
}
.checbox-text p {
	position: absolute;
}
@media (min-width: 1250px) {
	.main-box {
		padding: 0 50px 0 173px;
	}
}
@media (max-width: 1250px) {
	.form-box-bg .main-box {
		padding: 0 50px 0 5%;
	}
}
@media (max-width: 1023px) {
	.form-box-bg .form-box {
		padding-right: 0;
	}
	.form-box-bg img {
		bottom: 0;
		top: 45%;
	}
	.form-box-bg .main-box {
		padding: 0 5%;
	}
}
@media (max-width: 560px) {
	.form-box-bg .form-box .form-box-inside {
		padding-left: 2%;
		padding-right: 2%;
	}
}
[type="submit"] {
	cursor: pointer;
	transition: 0.3s ease-out;
	transition-property: background, color, border-color;
}
.thankyou-wrap {
	padding: 70px 0;
	@media (min-width: 600px) {
		padding: 150px 0;
	}
}

body {
   input[type="text"],
   [type="tel"],
   [type="email"],
   [type="number"] input {
      height: 1.8rem;
      margin-top: 22px;
   }
}