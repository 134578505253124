
.footer-top {

    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.5) 100%), url(../img/Intersection3.jpg);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2%;
    text-align: center;

    h2 {
        font-size: 40px;
        color: #ffffff;
        padding-top: 123px;
        margin: 0 auto;
        line-height: 55px;
    }

    a {
        border: 2px solid #ffffff;
        border-radius: 20px;
        color: #ffffff;
        font-size: 16px;
        padding: 10px 20px;
        text-transform: uppercase;
        transition: 0.5s;

    }

    a:hover {
        background-color: #ffffff;
        color: #0014A0;
        transition: 0.5s;
    }
}
.footer-main{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0 35px;
    
}
.button-footer {
    padding-top: 35px;
    padding-bottom: 66px;
}

.footer-box {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px 10px;
    img{
        padding:10px;
    }

    p {
        padding:10px;
        font-size: 15px;
        color: #707070;
        line-height: 20px;
    }
}

.footer-box-2 {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-right: 21px
}

.footer-box-1 {
    padding-left: 21px;
    display: flex;
    flex-wrap: wrap;
}
@media(max-width:560px){
.footer-top{
    padding: 0 2%;
    text-align: center;
}
}

