.product-box{
    margin-top: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
 
    h3{
        color: #ffffff;
        font-size: 22px;
        text-align: center;
        line-height: 34px;
    }
  
}
.box-product-bg-1{
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%),url(../img/Intersection5.jpg);
    background-size: cover;
    width: 450px;
    height: 117px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        position: absolute;
        bottom: 8px;
        right: 13px;
        width: 25px;
        height: 21px;

    }
}
.box-product-bg-2{
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%),url(../img/Intersection6.jpg);
    background-size: cover;
    width: 450px;
    height: 117px;
    padding: 0 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        position: absolute;
        bottom: 8px;
        right: 13px;
        width: 25px;
        height: 21px;


    }
}
.box-product-bg-3{
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%),url(../img/Intersection7.jpg);
    background-size: cover;
    width: 450px;
    height: 117px;
    display: flex;
    justify-content: center;
    align-items: center;
}
