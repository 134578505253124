.form-box-bg-hide{
    overflow: hidden;
    transition: 0.5s;
}

@keyframes easing {
    from {
        display: none;
        opacity: 0;
    }
    to {
        display: flex;
        opacity: 1;
    };
}

.hide-form{
    animation-name: easing;
    animation-timing-function: ease-in-out;
    animation-duration: .5s;
    animation-fill-mode: both;
    position: fixed;
    z-index: 9999999999999999;
    // display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto;
    width: 100%;
    // overflow: hidden;
    // display: flex;
    display: none;

   
  
    img{
        position: fixed;
        // top: 0;
        right: 300px;
        overflow: hidden;
        
    }
    .form-box {
        padding-right: 71px;
        margin: 0 auto;
        margin-top: 42px;
        z-index: 10;
        position: relative;
       overflow: hidden;
        .form-box-inside {
           max-width: 586px;
            padding-top: 38px;
            padding-left: 67px;
            padding-right: 61px;
            background: #0014A0 ;
            position: relative;
            overflow: hidden;
            z-index: 999999999999;
           
          
        }
            }
}
.hide-form-inside{
    overflow: hidden;
   position: relative;
    background-color: #0014A0;

}

    input[type='text'],
    [type="tel"],
    [type="email"],
    [type="number"] input {
       
        width: 45%;
        margin: 0;
        margin-top: 37px;
       
}

.button-form-box  button:hover {
    background-color: #0014A0;;
    color: #FFF;
    border: 2px solid #FFF;
    padding: 10px 29px 10px 30px;
    opacity: 1;
    a:hover{
        color: #FFF;
        opacity: 1;
}

}

