* body html{
   max-width: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Noto Sans', sans-serif;
}
nav{
    width: 100%;
    background-color: #0014A0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.grid-container{
    padding:0;
    margin: 0;
    max-width: 100%;
    
}
.grid-x{
    max-width: 1366px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.header-box{
    width: 100%;
   
    margin: 0 auto;
    background-color: #0014A0;
    display: flex;
    justify-content: space-between;
    
    .logo img{
        padding: 15px 0 19px 73px;
        cursor: pointer;
    }
    .logo img:hover{
        transition: ease-in 0.3s;
        opacity: 0.7;
         
    }
    .social-media{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        
    }
  
    .social-media img{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 29px;
        padding-bottom: 28px;
        padding-left: 25px;
      
       
    }
    .social-media img:hover{
        transition: 0.5s;
        opacity: 70%;
        -webkit-opacity: 70%;
        
    }
    .social-media :hover{
        transition: 0.5s;
        opacity: 70%;
        -webkit-opacity: 70%;
        
    }
    .last-img img{
        padding-right: 72px;

    }
   
 
}
.header-bg{
    width: 100%;
    height:100%;
    background-color: #000;
   
    
    .header-bg-2{
        background-image: url(../img/Intersection.png);
        background-size: auto;
        background-size: cover;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
       
        .header-bg-1{
            width: 1366px;
            
       
    }
    
        
    }
}
.header-text{
    max-width: 1366px;
      padding-left: 173px;
    color: #fff;

    
    h1{
        font-size: 42px;
        padding-top: 55px;
        line-height: 57px;
    }
    h2{
        font-size: 30px;
        line-height: 41px;
        padding-bottom: 30px;
        
    }
    p{
        font-size: 17px;
        opacity: .8;
        line-height: 23px;
        
    }
    p:nth-of-type(3){
        padding-bottom: 55px;
    }
}

.hide-form{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto;
    background-color: rgba(10, 18, 74,.8);
    padding: 20px;
    
}

  .form-box-size{
    justify-content: center;
    align-items: center;
      max-width: 584px;
      height: auto;
    
  }
    
    .hide-box{
        padding-right: 71px;
        margin: auto;
        // margin-top:42px ;
        background-color: #0014A0;
        width: 100%;
        height: auto;
      
        .hide-box-inside{
            position: relative;
            padding-top: 38px;
            padding-left: 67px;
            .hide-box-bg{
               
                background-image: url(../img/Subtraction1.png); 
                width: 100%;
                height: auto;
            
                
            }
            p{
                font-size: 8px;
                color: #ffffff;
                opacity: .5;
                line-height: 11px;
            }
        }
        
        h3{
            color: #ffffff;
            font-size: 22px;
            padding-bottom: 38px;
        }
    
    input[type='text'],[type="tel"],[type="email"],[type="number"]{
        background-color: transparent;
        box-shadow: none;
      
        color: #ffffff;
        border: none;
        opacity: .5;
        line-height: 22px;
        width: 100%;
        
    }
    .button-form-box{
        padding: 21px 0 39px;
        display: flex;
        justify-content: center;
        align-items: center;
        button{
          border: 2px solid #fff;
          
            font-size: 22px;
            color: #0014A0;
            background-color: #ffffff;
            padding: 10px 29px 10px 30px;
            border-radius: 24px;
            margin: 0 auto;
          
            text-align: center;
            text-transform: uppercase;
            a{
                font-size: 22px;
                color: #0014A0;
            }
        }
        button:hover {
            opacity: 70%;
            transition: ease 0.5s;
            
          
        }
    
    
    }
    input [type="checkbox"]{
        padding: 5px
    }
   
    
    hr{
        opacity: .5;
        padding-top:12px;
        margin:0;
    }}
    
    
    input:hover[type='text'],[type="tel"],[type="email"],[type="number"]{
        background-color: #0014A0;
    }


    .toggler{
        position: absolute;
            top: 0;
           left: 0;
            z-index: 2;
            cursor: pointer;
            width: 50px;
            height: 50px;
            background-color: #ffffff;
            display: flex;
            justify-content: flex-end;
            opacity: 0;
    }
    .form-box-size .hamburger {
        position: absolute;
        top: 2px;
        right: -72px;
        z-index: 1;
        width: 60px;
        height: 60px;
        padding: 1rem;
        background: var(--primary-color);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 80%;
        cursor: pointer;
        transition: 0.4s;
       
    }
    .form-box-size .hamburger:hover {
        opacity: 60%;
        transition: 0.4s;
    }
      
      /* Hamburger Line */
      .form-box-size .hamburger > div {
        position: relative;
        flex: none;
        width: 100%;
        height: 2px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s ease;
        transform: rotate(135deg);
       
      }
      
      /* Hamburger Lines - Top & Bottom */
      .form-box-size .hamburger > div::before,
      .form-box-size .hamburger > div::after {
        content: '';
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 2px;
        background: inherit;
        top: 0;
        transform: rotate(90deg);
      
      }

 
  
    @media(max-width: 1250px){
      
        .header-text{
            display: flex;
            display: block;
            justify-content: center;
            padding-left: 5%;  
          
      
      
    }
    @media(max-width: 1000px){
      
      
      .header-box .logo img{
          padding-left: 20%;
          padding-bottom: 0;
          padding-top: 0;

      }
      
    }
    @media(max-width: 560px){
        .header-box{
   
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
    }
    .hide-box-inside{
max-width: 100%;
    }
    .hide-box{
        padding-left: 2%;
    }
    .hide-box .hide-box-inside{
        padding-left: 2%;
        
    }

    .logo{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .header-box .logo img{
        padding-left: 0;
        padding-bottom: 0;
        padding-top: 20px;
    }
}
    }
